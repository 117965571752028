import React, { RefObject } from 'react'
import styled, { css } from 'styled-components'
import { Row } from 'ui/Containers'
import { Label, Small } from 'ui/Typography'

type BaseProps = Omit<React.HTMLProps<HTMLInputElement>, 'ref' | 'as'>
interface InputProps extends BaseProps {
  icon?: JSX.Element
  label?: string
  validator?: (value: string) => boolean
  errorMessage?: string
  refObj?: RefObject<HTMLInputElement>,
  setError?: boolean,
}

const Input: React.FC<InputProps> = (props) => {
  const { icon, label, validator, errorMessage, refObj, setError = false, ...rest } = props
  const [hasError, setHasError] = React.useState(false)
  const iconRef = React.useRef<HTMLDivElement>(null)

  const iconWidth = iconRef?.current?.clientWidth || 16
  const paddingLeft = icon ? `${20 + iconWidth + 8}px` : undefined
  
  React.useEffect(() => {
    setHasError(setError);
  }, [setError])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    if (value !== '' && validator) {
      const isValid = !validator(value)
      setHasError(isValid)
    } else {
      setHasError(false)
    }

    if (rest.onChange) {
      rest.onChange(event)
    }
  }

  return (
    <OuterContainer>
      {label && (
        <LabelContainer>
          <Label>{label}</Label>
        </LabelContainer>
      )}
      <InnerContainer>
        {icon && <IconContainer ref={iconRef}>{icon}</IconContainer>}
        <BaseInput
          {...rest}
          ref={refObj}
          paddingLeft={paddingLeft}
          onChange={handleChange}
          hasError={hasError}
        />
      </InnerContainer>
      {hasError && (
        <Row>
          <ErrorMessage>{errorMessage}</ErrorMessage>
        </Row>
      )}
    </OuterContainer>
  )
}

const BaseInput = styled.input<{ paddingLeft?: string; hasError: boolean }>`
  width: 100%;
  box-sizing: border-box;
  padding: 12px 20px;

  font-family: 'Source Sans Pro', sans-serif;

  background-color: ${(props) => props.theme.colors.grey};
  border-radius: ${(props) => props.theme.borderRadius};
  border: none;
  outline: none;

  ${(props) =>
    props.paddingLeft &&
    css`
      padding-left: ${props.paddingLeft};
    `}

  ${(props) =>
    props.hasError &&
    css`
      border: 1px solid ${props.theme.colors.danger};
    `}
`

const OuterContainer = styled.div`
  box-sizing: border-box;
  flex-grow: 1;
`

const InnerContainer = styled.div`
  position: relative;
`

const IconContainer = styled.div`
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
`

const LabelContainer = styled.div`
  padding: 0.5em 0;
  padding-left: 20px;
`

const ErrorMessage = styled(Small)`
  ${(props) => css`
    color: ${props.theme.colors.danger};
  `}
`

export default Input
