import React from 'react'
import styled from 'styled-components'
import { Page } from 'ui/Containers'

const Container = styled.footer`
  padding-top: 16px;
  padding-bottom: 46px;
`

const Footer: React.FC = (props) => {
  return (
    <Page>
      <Container {...props} />
    </Page>
  )
}

export default Footer
