import { DefaultTheme } from 'styled-components'

export const theme: DefaultTheme = {
  borderRadius: '999px',
  colors: {
    primary: '#802CF3',
    accent: '#0094FF',
    light: '#fff',
    dark: '#0f0f0f',
    background: '#f7f7f7',
    grey: '#EEEEF0',
    greyDark: '#838388',
    danger: '#D95040',
    success: '#57A200',
  },
}

export type Colors = keyof typeof theme['colors']

export const textColorFromBackground = (background: Colors): Colors => {
  switch (background) {
    case 'primary':
      return 'light'
    case 'accent':
      return 'light'
    case 'light':
      return 'dark'
    case 'dark':
      return 'light'
    case 'background':
      return 'dark'
    case 'grey':
      return 'dark'
    case 'greyDark':
      return 'light'
    case 'danger':
      return 'light'
    case 'success':
      return 'light'
    default:
      return 'dark'
  }
}
