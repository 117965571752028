import { initFirebase } from 'firebaseConfig'
import { GlobalStyle } from 'GlobalStyle'
import React from 'react'
import ReactDOM from 'react-dom'
import { IconContext } from 'react-icons'
import 'react-toastify/dist/ReactToastify.css'
import 'reset.css'
import { ThemeProvider } from 'styled-components'
import { theme } from 'theme'
import App from './App'
import reportWebVitals from './reportWebVitals'
import './toast.css'

initFirebase()

ReactDOM.render(
  <React.StrictMode>
  
      <IconContext.Provider value={{ color: theme.colors.greyDark }}>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <App />
        </ThemeProvider>
      </IconContext.Provider>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
