import styled from 'styled-components'
import * as system from 'styled-system'

/**
 * Box that applies box-shadow, padding and margin
 */
export const Box = styled.div`
  background-color: ${(props) => props.theme.colors.light};

  padding: 20px 16px;
  margin: 12px 0;

  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
`

/**
 * Flexbox Column that accepts `FlexboxProps` and `SpaceProps` and applies gap between children.
 */
export const Column = styled.div<system.SpaceProps & system.FlexboxProps>`
  ${system.space}
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  ${system.flexbox}

  & > * {
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 6px;
  }
`

/**
 * Flexbox Row that accepts `FlexboxProps` and `SpaceProps` and applies gap between children.
 */
export const Row = styled.div<system.SpaceProps & system.FlexboxProps>`
  ${system.space}

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  ${system.flexbox}

  & > * {
    box-sizing: border-box;
    margin-left: 6px;
    margin-right: 6px;
  }
`

/**
 * Responsive page container that centers it contents horizontally.
 * @info To support more responsive widths just duplicate `@media` block and replace `min/max-width` value.
 */
export const Page = styled.main`
  width: 100%;
  margin: 0 auto;
  @media (min-width: 500px) {
    max-width: 500px;
  }
`
