import { useAuth } from 'hooks/useAuth'
import React from 'react'
import { NavLink, NavLinkProps, Redirect, Route, Switch } from 'react-router-dom'
import styled from 'styled-components'
import { theme } from 'theme'
import { Page, Row } from 'ui/Containers'
import { Title } from 'ui/Typography'
import OverviewTasksPage from './OverviewTasksPage'
import OverviewTrackersPage from './OverviewTrackersPage'

const tabLinkProps: Omit<NavLinkProps, 'to'> = {
  exact: true,
  activeStyle: {
    color: theme.colors.primary,
  },
}

const OverviewPage: React.FC = () => {
  const { status } = useAuth()

  if (status === 'signedOut') {
    return <Redirect to="/create" />
  }

  return (
    <Page>
      <Row justifyContent="space-between">
        <TabLink to="/overview/trackers" {...tabLinkProps}>
          <Title color="inherit">Trackers</Title>
        </TabLink>
        <TabLink to="/overview/tasks" {...tabLinkProps}>
          <Title color="inherit">Tasks</Title>
        </TabLink>
      </Row>
      <Switch>
        <Route path="/overview/trackers">
          <OverviewTrackersPage />
        </Route>
        <Route path="/overview/tasks">
          <OverviewTasksPage />
        </Route>
      </Switch>
    </Page>
  )
}

const TabLink = styled(NavLink)`
  color: ${(props) => props.theme.colors.greyDark};
`

export default OverviewPage
