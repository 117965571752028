import React from 'react'

interface SidebarContext {
  isVisible: boolean
  toggle: () => void
}
const defaultValue: SidebarContext = {
  isVisible: false,
  toggle: console.log,
}
const sidebarContext = React.createContext(defaultValue)

export const SidebarProvider: React.FC = ({ children }) => {
  const [isVisible, setIsVisible] = React.useState(defaultValue.isVisible)

  const value = React.useMemo<SidebarContext>(
    () => ({
      isVisible,
      toggle: () => setIsVisible((prev) => !prev),
    }),
    [isVisible],
  )

  return <sidebarContext.Provider value={value}>{children}</sidebarContext.Provider>
}

export const useSidebar = () => React.useContext(sidebarContext)
