import React from 'react'
import styled, { css } from 'styled-components'
import { Colors, textColorFromBackground } from 'theme'

type ButtonType = 'outlined' | 'filled'
type ButtonSize = 'small' | 'large'
type BaseProps = Omit<React.HTMLProps<HTMLAnchorElement>, 'ref' | 'as' | 'size'>
interface ButtonProps extends BaseProps {
  type?: ButtonType
  icon?: JSX.Element
  color?: Colors
  full?: boolean
  size?: ButtonSize
}

const Button: React.FC<ButtonProps> = (props) => {
  const {
    type = 'filled',
    icon,
    color = 'primary',
    children,
    full = true,
    size = 'large',
    ...rest
  } = props

  return (
    <BaseButton {...rest} color={color} buttonType={type} full={full} size={size}>
      {icon && <IconContainer>{icon}</IconContainer>}
      {children}
    </BaseButton>
  )
}

interface BaseButtonProps {
  color: Colors
  buttonType: ButtonType
  full: boolean
  size: ButtonSize
}

const BaseButton = styled.a<BaseButtonProps>`
  border: none;
  outline: none;
  user-select: none;
  text-decoration: none;
  cursor: pointer;
  display: block;

  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);

  box-sizing: border-box;

  ${(props) => {
    switch (props.size) {
      case 'large':
        return css`
          padding: 16px;
          font-size: 16px;
        `
      case 'small':
        return css`
          padding: 14px;
          font-size: 14px;
        `
    }
  }}

  ${(props) => {
    if (props.full) {
      return css`
        flex-grow: 1;
        min-width: 200px;
      `
    }
  }}

  border-radius: ${(props) => props.theme.borderRadius};

  font-family: 'Source Sans Pro', sans-serif;
  font-style: normal;
  font-weight: bold;
  text-align: center;

  ${(props) => {
    const { buttonType, color, theme } = props
    switch (buttonType) {
      case 'filled':
        return css`
          background-color: ${theme.colors[color]};
          color: ${theme.colors[textColorFromBackground(color)]};
        `
      case 'outlined':
        return css`
          border: 2.5px solid ${theme.colors[color]};
          color: ${theme.colors[textColorFromBackground(color)]};
        `
    }
  }}
`

const IconContainer = styled.span`
  margin-right: 1em;
`

export default Button
