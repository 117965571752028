import { logout, useAuth } from 'hooks/useAuth'
import React from 'react'
import Dock from 'react-dock'
import { Link, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { theme } from 'theme'
import { Box, Column, Row } from 'ui/Containers'
import { Text, Title } from 'ui/Typography'
import { useSidebar } from '../hooks/useSidebar'
import Button from './Button'

const Sidebar: React.FC = () => {
  const history = useHistory()
  const { isVisible, toggle } = useSidebar()
  const { status } = useAuth()

  const handleLogout = () => {
    logout()
    toggle()
    history.push('/create')
  }

  const handleLogin = () => {
    history.push('/login')
  }

  React.useEffect(() => {
    const unsubscribe = history.listen(() => {
      if (isVisible) {
        toggle()
      }
    })

    return () => {
      unsubscribe()
    }
  }, [toggle, isVisible, history])

  return (
    <Dock isVisible={isVisible} position="right" dimMode="opaque" onVisibleChange={toggle}>
      <FullHeightColumn justifyContent="space-between" padding={20} flex={1}>
        <Column>
          <Title>Menu</Title>
          {status === 'signedIn' && (
            <>
              <SidebarLink to="/overview/trackers" label="Trackers" />
              <SidebarLink to="/overview/tasks" label="My Tasks" />
              <SidebarLink to="/" label="Templates" hint="(Coming Soon)" disabled={true} />
            </>
          )}
        </Column>
        <Column>
          {status === 'signedIn' ? (
            <Button onClick={handleLogout}>Logout</Button>
          ) : (
            <Button onClick={handleLogin}>Login</Button>
          )}
        </Column>
      </FullHeightColumn>
    </Dock>
  )
}

interface SidebarLinkProps {
  to: string
  label: string
  hint?: string
  disabled?: boolean
}

const SidebarLink: React.FC<SidebarLinkProps> = (props) => {
  const { to, label, hint, disabled } = props

  return (
    <Link to={to} style={{ pointerEvents: disabled ? 'none' : 'auto' }}>
      <LinkContainer>
        <Row marginY={0}>
          <Text>{label}</Text>
          {hint && <Text color={theme.colors.greyDark}>{hint}</Text>}
        </Row>
      </LinkContainer>
    </Link>
  )
}

const LinkContainer = styled(Box)`
  margin: 0;
`

const FullHeightColumn = styled(Column)`
  height: 100%;
`

export default Sidebar
