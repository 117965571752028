import Button from 'components/Button'
import SearchBar from 'components/SearchBar'
import { firestore } from 'firebaseConfig'
import { useAuth } from 'hooks/useAuth'
import React from 'react'
import { FaCheckCircle, FaHourglass } from 'react-icons/fa'
import { useHistory } from 'react-router-dom'
import { theme } from 'theme'
import { Project } from 'types'
import { Box, Column, Row } from 'ui/Containers'
import { Heading, Text } from 'ui/Typography'

const OverviewTrackersPage: React.FC = () => {
  const history = useHistory()
  const { user } = useAuth()

  const [completedProjects, setCompletedProjects] = React.useState<Project[]>([])
  const [openProjects, setOpenProjects] = React.useState<Project[]>([])

  const loadOverview = async () => {
    if (!user || !user.email) return
    const { completed, open } = await fetchUserProjects(user.email)
    setCompletedProjects(completed)
    setOpenProjects(open)
  }

  const handleLaunchNew = () => {
    history.push('/create')
  }
  
  const onTrackerSelect = (projectId: string) => () => {
    history.push(`/trackers/${projectId}`)
  };

  React.useEffect(() => {
    loadOverview()
  }, [user, user?.email, loadOverview])

  const hasCompletedProjects = completedProjects.length !== 0
  const hasOpenProjects = openProjects.length !== 0

  if (!hasCompletedProjects && !hasOpenProjects) {
    return <Column alignItems="center" justifyContent="center"></Column>
  }

  return (
    <Column>
      <SearchBar />
      {hasOpenProjects && (
        <>
          <Row alignItems="center" marginTop="1em">
            <FaHourglass color={theme.colors.dark} />
            <Heading>Currently open</Heading>
          </Row>
          <Column>
            {openProjects.map((project) => (
              <Box className="cursor-pointer" key={project.projectID} onClick={onTrackerSelect(project.projectID)}>
                <Text>{project.name}</Text>
              </Box>
            ))}
          </Column>
        </>
      )}

      {hasCompletedProjects && (
        <>
          <Row alignItems="center" marginTop="1em">
            <FaCheckCircle color={theme.colors.success} />
            <Heading>Completed</Heading>
          </Row>
          <Column>
            {completedProjects.map((project) => (
              <Box className="cursor-pointer" key={project.projectID} onClick={onTrackerSelect(project.projectID)}>
                <Text>{project.name}</Text>
              </Box>
            ))}
          </Column>
        </>
      )}

      {/* Actions */}
      <Column marginTop="2em">
        <Button onClick={handleLaunchNew}>Launch New</Button>
      </Column>
    </Column>
  )
}

interface FetchUserProjectsResult {
  completed: Project[]
  open: Project[]
}

async function fetchUserProjects(email: string): Promise<FetchUserProjectsResult> {
  const response = await firestore().collection('trackers').where('owner', '==', email).get()
  const completed: Project[] = []
  const open: Project[] = []

  response.forEach((doc) => {
    const data = doc.data() as Omit<Project, 'projectID'>
    const project: Project = { projectID: doc.id, ...data }
    if (project.isCompleted) {
      completed.push(project)
    } else {
      open.push(project)
    }
  })

  return { completed, open }
}

export default OverviewTrackersPage
