import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'

const prodConfig = {
  apiKey: 'AIzaSyDCJanP0clExaL55yVw-bQ8AOS6m5TFQnA',
  authDomain: 'eduro-trackit.firebaseapp.com',
  projectId: 'eduro-trackit',
  storageBucket: 'eduro-trackit.appspot.com',
  messagingSenderId: '511740694108',
  appId: '1:511740694108:web:39b20361dd65e44a1ea099',
}

const devConfig = {
  apiKey: 'AIzaSyAbCBmyJX_uQJu5RirRsuFGCBEr2-CBXek',
  authDomain: 'eduro-trackit-dev.firebaseapp.com',
  projectId: 'eduro-trackit-dev',
  storageBucket: 'eduro-trackit-dev.appspot.com',
  messagingSenderId: '1094637304003',
  appId: '1:1094637304003:web:32054ddbdc770f00df0a76',
}

const isDev =
  (process.env.REACT_APP_CI &&
    process.env.REACT_APP_CI === 'true' &&
    process.env.REACT_APP_CI_ENV === 'staging') ||
  !process.env.NODE_ENV ||
  process.env.NODE_ENV === 'development'

export const initFirebase = (): void => {
  firebase.initializeApp(isDev ? devConfig : prodConfig)
}

export const firestore = firebase.firestore
export const auth = firebase.auth
export const functions = firebase.functions

// if (isDev) {
//   // ONLY FOR EASIER DEV
//   // @ts-ignore
//   window.firebase = firebase
// }
