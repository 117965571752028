import styled from 'styled-components'
import * as system from 'styled-system'

export const Title = styled.h1<system.TypographyProps>`
  margin: 16px 0;

  font-family: 'Source Sans Pro', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 41px;

  text-transform: 'capitalize';

  color: ${(props) => (props.color ? props.color : props.theme.colors.primary)};
  ${system.typography}
`

export const Heading = styled.h2<system.TypographyProps>`
  font-family: 'Source Sans Pro', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 26px;

  text-transform: uppercase;

  color: ${(props) => (props.color ? props.color : props.theme.colors.dark)};
  ${system.typography}
`

export const Label = styled.span<system.TypographyProps>`
  font-family: 'Source Sans Pro', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;

  color: ${(props) => (props.color ? props.color : props.theme.colors.dark)};
  ${system.typography}
`

export const Text = styled.p<system.TypographyProps>`
  font-family: 'Source Sans Pro', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;

  color: ${(props) => (props.color ? props.color : props.theme.colors.dark)};
  ${system.typography}
`

export const Small = styled.small<system.TypographyProps>`
  font-family: 'Source Sans Pro', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;

  color: ${(props) => (props.color ? props.color : props.theme.colors.dark)};
  ${system.typography}
`
