import { orderBy } from 'lodash';
import moment from 'moment';
import Button from 'components/Button'
import SearchBar from 'components/SearchBar'
import { firestore } from 'firebaseConfig'
import { useAuth } from 'hooks/useAuth'
import React from 'react'
import { FaCheckCircle, FaHourglass } from 'react-icons/fa'
import { useHistory } from 'react-router-dom'
import { theme } from 'theme'
import { Task } from 'types'
import { Box, Column, Row } from 'ui/Containers'
import { Heading, Text } from 'ui/Typography'

const OverviewTasksPage: React.FC = () => {
  const history = useHistory()
  const { user } = useAuth()

  const [completedTasks, setCompletedTasks] = React.useState<Task[]>([])
  const [openTasks, setOpenTasks] = React.useState<Task[]>([])

  const handleLaunchNew = () => {
    history.push('/create')
  }
  
  const onTaskClick = (projectId: string, taskId: string) => () => {
    history.push(`/trackers/${projectId}/tasks/${taskId}`)
  };
  
  const sortInAsc = (tasks: Task[]) => {
    return orderBy(tasks, (task) => moment(task.dueDate).format('YYYYMMDD'), ['asc'])
  };

  React.useEffect(() => {
    const loadOverview = async () => {
      if (!user || !user.email) return
      const { completed, open } = await fetchUserTasks(user.email)
      setCompletedTasks(completed)
      setOpenTasks(open)
    }

    loadOverview()
  }, [user, user?.email])

  const hasCompletedTasks = completedTasks.length !== 0
  const hasOpenTasks = openTasks.length !== 0

  if (!hasCompletedTasks && !hasOpenTasks) {
    return <Column alignItems="center" justifyContent="center"></Column>
  }

  return (
    <Column>
      <SearchBar />
      {hasOpenTasks && (
        <>
          <Row alignItems="center" marginTop="1em">
            <FaHourglass color={theme.colors.dark} />
            <Heading>Currently open</Heading>
          </Row>
          <Column>
            {sortInAsc(openTasks).map((task) => (
              <Box className="cursor-pointer" key={task.taskID} onClick={onTaskClick(task.projectID, task.taskID)}>
                <Text>{task.description}</Text>
              </Box>
            ))}
          </Column>
        </>
      )}

      {hasCompletedTasks && (
        <>
          <Row alignItems="center" marginTop="1em">
            <FaCheckCircle color={theme.colors.success} />
            <Heading>Completed</Heading>
          </Row>
          <Column>
            {sortInAsc(completedTasks).map((task) => (
              <Box className="cursor-pointer" key={task.taskID} onClick={onTaskClick(task.projectID, task.taskID)}>
                <Text>{task.description}</Text>
              </Box>
            ))}
          </Column>
        </>
      )}

      {/* Actions */}
      <Column marginTop="2em">
        <Button onClick={handleLaunchNew}>Launch New</Button>
      </Column>
    </Column>
  )
}

interface FetchUserTasksResult {
  completed: Task[]
  open: Task[]
}

async function fetchUserTasks(email: string): Promise<FetchUserTasksResult> {
  const response = await firestore().collectionGroup('tasks').where('owner', '==', email).get()
  const completed: Task[] = []
  const open: Task[] = []

  response.forEach((doc) => {
    const data = doc.data() as Omit<Task, 'taskID'>
    const task: Task = { taskID: doc.id, ...data }
    if (task.inProgress) {
      completed.push(task)
    } else {
      open.push(task)
    }
  })

  return { completed, open }
}

export default OverviewTasksPage
