import { ReactComponent as Logo } from 'assets/logo.svg'
import { useSidebar } from 'hooks/useSidebar'
import React from 'react'
import { FaBars } from 'react-icons/fa'
import styled from 'styled-components'
import { theme } from 'theme'
import { Page } from 'ui/Containers'

const Container = styled.header`
  padding-top: 46px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const MenuButton = styled.button`
  border: none;
  background: none;
  user-select: none;
  outline: none;
  padding: 1em;
  cursor: pointer;
`

const Header: React.FC = () => {
  const { toggle } = useSidebar()
  return (
    <Page>
      <Container>
        <Logo />
        <MenuButton onClick={toggle}>
          <FaBars size={23} color={theme.colors.dark} />
        </MenuButton>
      </Container>
    </Page>
  )
}

export default Header
