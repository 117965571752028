import React from 'react'
import { Page } from 'ui/Containers'

/**
 * Displayed when lazy-loading a Page.
 * Should *not* contain any elements and should be of the same background as app since it's shown for a brief moment.
 */
const Loader: React.FC = () => {
  return <Page></Page>
}

export default Loader
