import Footer from 'components/Footer'
import Header from 'components/Header'
import Loader from 'components/Loader'
import Sidebar from 'components/Sidebar'
import { AuthProvider } from 'hooks/useAuth'
import { SidebarProvider } from 'hooks/useSidebar'
import OverviewPage from 'pages/OverviewPage'
import React, { Suspense } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import './app.css'

const WorkStationDesignPage = React.lazy(() => import('pages/WorkStationDesignPage'))
const StartProjectPage = React.lazy(() => import('pages/StartProjectPage'))
const TaskDetailsPage = React.lazy(() => import('pages/TaskDetailsPage'))
const TaskNotesPage = React.lazy(() => import('pages/TaskNotesPage'))
const NoMatchPage = React.lazy(() => import('pages/NoMatchPage'))
const SignUpPage = React.lazy(() => import('pages/auth/SignUpPage'))
const SignInPage = React.lazy(() => import('pages/auth/SignInPage'))
const ForgotPasswordPage = React.lazy(() => import('pages/auth/ForgotPasswordPage'))

const App: React.FC = () => {
  return (
    <Router>
      <AuthProvider>
        <SidebarProvider>
          <Header />
          <Sidebar />
          <ToastContainer />
          <Suspense fallback={<Loader />}>
            <Switch>
              <Route path="/login">
                <SignInPage />
              </Route>
              <Route path="/sign-up">
                <SignUpPage />
              </Route>
              <Route path="/forget-password">
                <ForgotPasswordPage />
              </Route>
              <Route path="/trackers/:projectID" exact>
                <WorkStationDesignPage />
              </Route>
              <Route path="/trackers/:projectID/tasks/:taskID" exact>
                <TaskDetailsPage />
              </Route>
              <Route path="/trackers/:projectID/tasks/:taskID/notes">
                <TaskNotesPage />
              </Route>
              <Route path="/create">
                <StartProjectPage />
              </Route>
              <Route path="/overview">
                <OverviewPage />
              </Route>
              <Route path="*">
                <NoMatchPage />
              </Route>
            </Switch>
          </Suspense>
          <Footer />
        </SidebarProvider>
      </AuthProvider>
    </Router>
  )
}

export default App
