import { auth, firestore } from 'firebaseConfig'
import React from 'react'
import { toast } from 'react-toastify'

export type UserTier = 'guest' | 'starter' | 'premium1'
export type AuthStatus = 'loading' | 'signedIn' | 'signedOut'
export type User = {
  uid: string
  email: string | null
}
interface UserProfile {
  name: string
  tier: UserTier
}
interface AuthContext {
  user?: User
  profile?: UserProfile
  status: AuthStatus
}
const authContext = React.createContext<AuthContext>({ status: 'loading' })

export const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = React.useState<User>()
  const [status, setStatus] = React.useState<AuthStatus>('loading')
  const [profile, setProfile] = React.useState<UserProfile>()

  const loadProfile = async (uid: string) => {
    const profile = await getUserProfile(uid)
    setProfile(profile)
  }

  // Subscribe to changes in auth state
  React.useEffect(() => {
    const unsubscribe = auth().onAuthStateChanged((user) => {
      if (user) {
        setUser({
          uid: user.uid,
          email: user.email,
        })
        setStatus('signedIn')
        loadProfile(user.uid)
      } else {
        setUser(undefined)
        setProfile(undefined)
        setStatus('signedOut')
      }
    })

    return () => {
      unsubscribe()
    }
  }, [])

  const value = React.useMemo<AuthContext>(
    () => ({
      user,
      profile,
      status,
    }),
    [user, profile, status],
  )

  return <authContext.Provider value={value}>{children}</authContext.Provider>
}

export const useAuth = () => React.useContext<AuthContext>(authContext)

export function login(email: string, password: string) {
  return auth().signInWithEmailAndPassword(email, password)
}

export async function register(
  email: string | undefined,
  password: string | undefined,
  name: string | undefined,
) {
  if (!email || !password || !name) throw new Error('Missing fields during register')
  const { user } = await auth().createUserWithEmailAndPassword(email, password)
  if (user) {
    await login(email, password)
    await setUserProfile(user.uid, { name, tier: 'starter' })
  }
}

export async function logout() {
  try {
    await auth().signOut()
    toast.success('Logged out')
  } catch (error) {
    toast.error('Error logging out')
  }
}

export function updateUserTier(uid: string, tier: UserTier) {
  return firestore().collection('users').doc(uid).update({ tier })
}

export function setUserProfile(uid: string, profile: UserProfile) {
  return firestore().collection('users').doc(uid).set(profile)
}

export async function getUserProfile(uid: string): Promise<UserProfile> {
  const response = await firestore().collection('users').doc(uid).get()
  return response.data() as UserProfile
}

export async function forgotPassword(email: string) {
  return auth().sendPasswordResetEmail(email)
}
